$(document).ready(function() {

	/* =====  MOBILE MENU TOGGLE  ====== */

	$('.offCanvasToggler').click(function(event) {
		event.preventDefault();
		$('.off-canvas-nav').toggleClass('active');
		$('.fullscreen-overlay').toggleClass('active');
		$('html, body').toggleClass('no-scrolling');
	});

	/*======  INITS  ======*/

	/*----------  BOOTSTRAP SELECT  ----------*/

	$('.selectpicker').selectpicker();

	/*----------  PICKADATE.JS  ----------*/

	$('.datepicker').pickadate({
		monthsFull: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
		monthsShort: ['jan', 'feb', 'mar', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
		weekdaysFull: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
		weekdaysShort: ['zon', 'maa', 'din', 'woe', 'don', 'vrij', 'zat'],
		today: 'Vandaag',
		clear: 'Wissen',
		close: 'Sluiten',
		labelMonthNext: 'Volgende maand',
		labelMonthPrev: 'Vorige maand',
		labelMonthSelect: 'Kies een maand',
		labelYearSelect: 'Kies een jaar',
		selectYears: true,
		selectMonths: true,
		firstDay: 1
	});

	/*======  SLIDERS  ======*/

	/*----------  FULL WIDTH SLIDERS  ----------*/

	$('.full-width-slider .slides').slick({
		dots: false,
		infinite: true,
		prevArrow: $('.full-width-slider .slider-controls .prev-slide'),
		nextArrow: $('.full-width-slider .slider-controls .next-slide')
	});
});

/*======  ACTIONS  ======*/

/*----------  BLOCK TOGGLE  ----------*/

$('.block-header').click(function(event) {
	event.preventDefault();

	if ($.isEmptyObject($(this).data('block'))) {
		// NIKS
	} else {
		$(this).toggleClass('active');
		$(`.block-content[data-block="${$(this).data('block')}"]`).slideToggle();
	}
});

/*----------  MIJN CAMPINGS REMOVE PERIODE  ----------*/

$('.sluitingsperiodes .delete-button').click(function(event) {
	event.preventDefault();
	$(this).parents('.table-row').remove();
});

/*----------  MIJN CAMPINGS ADD PERIODE  ----------*/

$('.sluitingsperiodes .add-row').click(function(event) {
	event.preventDefault();

	// $('.sluitingsperiodes .table-rows .table-row:last').clone().appendTo('.sluitingsperiodes .table-rows');
	var row_to_clone = $('.sluitingsperiodes .table-rows .table-row:last').clone();
	row_to_clone.find('input').val();
	row_to_clone.appendTo('.sluitingsperiodes .table-rows');
});
